<template>
    <div id="centerRight">
        <div class="bg-color-black">
            <div class="d-flex pt-2 pl-2">
                <span>
                    <icon name="chart-bar" class="text-icon"></icon>
                </span>
                <div class="d-flex">
                    <span class="text mx-2">{{ title }}</span>
                </div>
            </div>
            <div>
                <BottomLeftChart :cdata="cdata" />
            </div>
        </div>
    </div>
</template>

<script>
import BottomLeftChart from '@/components/echart/bottom/bottomLeftChart/chart'
export default {
    props: ['cdata', 'title'],
    components: {
        BottomLeftChart
    }
}
</script>

<style lang="scss" scoped>
$box-height: 450px;
$box-width: 430px;

#centerRight {
    padding: 16px;
    height: $box-height;
    width: $box-width;
    border-radius: 5px;

    .bg-color-black {
        height: $box-height - 20px;
        border-radius: 10px;
    }

    .text {
        color: #c3cbde;
    }

    .chart-box {
        margin-top: 16px;
        width: 170px;
        height: 170px;

        .active-ring-name {
            padding-top: 10px;
        }
    }
}
</style>