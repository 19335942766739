<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 class="dv-dec-10" />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
            <div class="title">
              <!-- <div class="title-text1">衡阳<img src="https://admin.yjwkj.com.cn/img/logo.8bfc1199.png" class="logoImg">公安</div> -->
              <!-- <div class="dv-dec-6"><img src="../../public/logo.svg" class="logo"><span class="title-text2">访囿住宿大数据</span></div> -->
              <span class="title-text">网约房治安管理智能预警</span>
              <dv-decoration-6
                class="dv-dec-6"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
              />
            </div>
            <dv-decoration-8 class="dv-dec-8" :reverse="true" :color="decorationColor" />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
        </div>

        <!-- 第二行 -->
        <div class="d-flex jc-between px-2">
          <div class="d-flex aside-width">
            <div class="react-left ml-4 react-l-s">
              <span class="react-left"></span>
              <div class="text" @click="goHome()">
                <dv-border-box-12><div style="width: 280px;text-align:center;cursor: pointer;">网约房治安管理智能预警</div></dv-border-box-12>
              </div>
            </div>
          </div>
          <div class="d-flex aside-width">
            
            <div class="react-right mr-4 react-l-s">
              <div style="margin-left: 25px;" class="text" v-for="(item, i) in tabList" :key="i" @click="change(item, i)">
                <div v-if="i == tabIndex"><dv-border-box-10><div style="width: 100px; text-align:center;" :class="['text-cursor', i == tabIndex?'active':'']">{{ item.name }}</div></dv-border-box-10></div>
                <div v-if="i !== tabIndex"><dv-border-box-12><div style="width: 100px; text-align:center;" :class="['text-cursor', i == tabIndex?'active':'']">{{ item.name }}</div></dv-border-box-12></div>
              </div>
              <span style="margin-right: 100px;"></span>
              <span class="react-after"></span>
              <span class="text">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span>
            </div>
          </div>
        </div>

        <div class="body-box">
          <!-- 第三行数据 -->
          <div class="content-box">
            <div>
              <dv-border-box-13>
                <centerRight1 :cycle="tabName" :tabValue="tabValue" />
              </dv-border-box-13>
            </div>
            <!-- 中间 -->
            <div>
              <centerLeft2 :sumCount="sumCount" :thatMonthCount="thatMonthCount" :thatDayCount="thatDayCount"
                :cdata="centerData" />
            </div>
            <!-- 中间 -->
            <div>
              <dv-border-box-12>
                <centerRight :cdata="rtData" :title="'网约房-区域'+  tabValue +'上传统计'" />
              </dv-border-box-12>
            </div>
          </div>

          <!-- 第四行数据 -->
          <div class="bottom-box">
            <div>
              <dv-border-box-13>
                <lbData :cycle="tabName" :tabValue="tabValue"></lbData>
              </dv-border-box-13>
            </div>
            <div></div>
            <div>
              <dv-border-box-12>
                <centerRight :cdata="rbData" :title="'网约房-区域上传总数统计'" />
              </dv-border-box-12>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin";
import { formatTime } from '../utils/index.js'
import centerLeft2 from './centerLeft2'
import centerRight1 from './centerRight1'
import centerRight from './centerRight'
import lbData from './lbData'

export default {
  mixins: [drawMixin],
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      decorationColor: ['#568aea', '#000000'],
      thatMonthCount: 0, // 当月上传总数
      thatDayCount: 0, // 当日上传总数
      sumCount: 0, // 总记录数
      rtData: [], // 右上角数据
      rbData: [], // 右下角数据
      centerData: [],
      qxCode: [
        {
          // 名字需要与 “common/map/fujian.js” 地图数据文件里面定义的一一对应，不能是 “福州” 或者 “闽” 之类的缩写
          name: '珠晖区',
          orgCode: "A02A04"
        },
        {
          name: '雁峰区',
          orgCode: "A02A03"
        },
        {
          name: '石鼓区',
          orgCode: "A02A01"
        },
        {
          name: '蒸湘区',
          orgCode: "A02A02"
        },
        {
          name: '南岳区',
          orgCode: "A02A05"
        },
        {
          name: '衡阳县',
          orgCode: "A02A07"
        },
        {
          name: '衡南县',
          orgCode: "A02A08"
        },
        {
          name: '衡山县',
          orgCode: "A02A09"
        },
        {
          name: '衡东县',
          orgCode: "A02A10"
        },
        {
          name: '祁东县',
          orgCode: "A02A11"
        },
        {
          name: '耒阳市',
          orgCode: "A02A12"
        },
        {
          name: '常宁市',
          orgCode: "A02A13"
        },
        {
          name: '高新区',
          orgCode: "A02A06"
        }
      ],
      tabIndex: 0,
      tabName: 'day',
      tabValue: '当天',
      tabList:[
        {
          name: '当天',
          cycle: 'day'
        },{
          name: '本周',
          cycle: 'week'
        },
        {
          name: '本月',
          cycle: 'month'
        }
      ]
    }
  },
  components: {
    centerLeft2,
    centerRight1,
    centerRight,
    lbData
  },
  mounted() {
    this.timeFn()
    this.cancelLoading()
  },
  created() {
    // 初次请求
    this.getUploadRanking()
    this.getareaStoreTotal()
    this.getUploadSumRanking()

    // 设置半小时定时发送请求
    setInterval(() => {
      this.getUploadRanking(this.tabName)
      this.getareaStoreTotal()
      this.getUploadSumRanking()
    }, 1800000)
  },
  beforeDestroy() {
    clearInterval(this.timing)
  },
  methods: {
    goHome() {
      this.$router.push('/home')
    },
    // 改变值
    change(obj, index) {
      this.getUploadRanking(obj.cycle)
      this.tabValue = obj.name
      this.tabName = obj.cycle
      this.tabIndex = index
    },
    // 获取右上角排行
    getUploadRanking(cycle = 'day') {
      const querystring = require('querystring');
      this.$http.post('/bigScreen/query/areaUploadRanking', querystring.stringify({
        orgCode:'A02',
        cycle: cycle
      })).then(res => {
        let cdata = {
          category: [],
          barData: []
        }
        cdata.category = res.data.result.map(x => x.departName)
        cdata.barData = res.data.result.map(x => x.uploadNum)
        this.rtData = cdata
      })
    },
    // 右下角数据
    getUploadSumRanking() {
      this.$http.post('/bigScreen/query/areaUploadSumRanking?orgCode=A02').then(res => {
        let cdata = {
          category: [],
          barData: []
        }
        cdata.category = res.data.result.map(x => x.departName)
        cdata.barData = res.data.result.map(x => x.uploadNum)
        this.rbData = cdata
      })
    },
    // 网约房-区域总数查询
    getareaStoreTotal() {
      this.$http.post('/bigScreen/query/areaStoreTotal?orgCode=A02').then(res => {
        this.sumCount = res.data.result.sumCount
        this.thatDayCount = res.data.result.thatDayCount
        this.thatMonthCount = res.data.result.thatMonthCount

        // 合并 orgCode 相同的两个数组
        const combined = res.data.result.areaStoreList.reduce((acc, cur) => {
          const target = acc.find(e => e.orgCode === cur.orgCode)
          if (target) {
            Object.assign(target, cur)
          } else {
            acc.push(cur)
          }
          return acc
        }, this.qxCode)
        this.centerData = combined.map(x => Object.assign({}, {
          name: x.name,
          value: x.storeNum
        }))
      })
    },
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss')
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';

.logoImg {
  width: 60px;
  height: 60px;
  vertical-align: -90%;
}

.logo {
  width: 40px;
  height: 40px;
  vertical-align: -50%;
}

.title-text1 {
  font-size: 24px;
  font-weight: bold;
}

.title-text2 {
  font-size: 28px;
}
</style>
