<template>
  <div>
    <!-- 年度开工率 -->
    <Echart :options="options" id="bottomLeftChart" height="425px" width="100%"></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = {
          tooltip: {
            trigger: "axis",
            // backgroundColor: "rgba(255,255,255,0.1)",
            axisPointer: {
              type: "shadow",
              // label: {
              //   show: true,
              //   backgroundColor: "#7B7DDC"
              // }
            }
          },
          legend: {},
          grid: {
            left: '3%',
            top: '5%',
            bottom: '7%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
          },
          yAxis: {
            type: 'category',
            data: newData.category
          },
          series: [
            {
              type: "bar",
              data: newData.barData,
              label: {
                show: true,
                position: 'right',
                color: '#fff'
              },
            }
          ]
        }
      },
      immediate: true,
      deep: true
    },
  },
}
</script>