<template>
  <div id="centerRight1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-line" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class=" text mx-2">网约房-区域{{ tabValue }}排行统计</span>
        </div>
      </div>
      <div class="d-flex jc-center body-box">
        <dv-scroll-board class="dv-scr-board" :config="config" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['cycle', 'tabValue'],
  data() {
    return {
      cdata:[],
      config: {
        header: ['区域', '上传数'],
        data: [['正在加载...', '正在加载...']],
        rowNum: 7, //表格行数
        headerHeight: 35,
        headerBGC: '#0f1325', //表头
        oddRowBGC: '#0f1325', //奇数行
        evenRowBGC: '#171c33', //偶数行
        index: true,
        columnWidth: [50,250],
        align: ['center']
      }
    }
  },
  watch: {
    cdata: {
      handler(val) {
        let arr = val
        let nowData = this.config
        nowData.data = arr
        this.config = { ...nowData }
      },
      deep: true,
    },
    cycle: {
      handler(val) {
        this.getUploadRanking(val)
      }
    }
  },
  created() {
    this.getUploadRanking()

    // 设置半小时定时发送请求
    setInterval(() => {
      this.getUploadRanking(this.cycle)
    }, 1800000)
  },
  methods: {
    // 获取左上角排行榜
    getUploadRanking(cycle = 'day') {
      const querystring = require('querystring');
      this.$http.post('/bigScreen/query/areaUploadRanking', querystring.stringify({
        orgCode:'A02',
        cycle: cycle
      })).then(res => {
        this.cdata = res.data.result.map(x => Object.assign([],[
          x.departName,
          x.uploadNum
        ]))
      })
    },
  }
}
</script>

<style lang="scss" scoped>
$box-height: 460px;
$box-width: 425px;

#centerRight1 {
  padding: 16px;
  padding-top: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;

  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }

  .text {
    color: #c3cbde;
  }

  .body-box {
    border-radius: 10px;
    overflow: hidden;

    .dv-scr-board {
      width: 400px;
      height: 395px;
    }
  }
}
</style>
