<template>
  <div id="centerLeft1">
    <div class="middle_top">
      <div class="middle_top_list">
        <div class="list_title">累计上传数</div>
        <div class="list_num list_num1"><span class="counter-value">{{ sumCount }}</span>条</div>
      </div>
      <div class="middle_top_list">
        <div class="list_title">累计当月上传数</div>
        <div class="list_num list_num2"><span class="counter-value">{{ thatMonthCount }}</span>条</div>
      </div>
      <div class="middle_top_list">
        <div class="list_title">当日上传数</div>
        <div class="list_num list_num3"><span class="counter-value">{{ thatDayCount }}</span>条</div>
      </div>
    </div>
    <div class="d-flex jc-center" style="margin-top: 30px;">
      <CenterLeft2Chart :cdata="cdata" />
    </div>
  </div>
</template>

<script>
import CenterLeft2Chart from "@/components/echart/centerLeft/centerLeft2Chart/chart";
export default {
  props:['thatDayCount', 'thatMonthCount', 'sumCount', 'cdata'],
  components: {
    CenterLeft2Chart
  },
  data() {
    return {
      
    }
  }
};
</script>

<style lang="scss" scoped>
#centerLeft1 {
  $box-width: 300px;
  $box-height: 465px;
  padding: 16px;
  height: $box-height;
  min-width: $box-width;
  border-radius: 5px;

  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }

  .middle_top {
    width: 100%;
    display: flex;
  }

  .middle_top_list {
    width: 33.2%;
    margin-top: 5%;
    text-align: center;
    color: #4ADEFE;
  }

  .middle_top_list span {
    color: #F3DB5C;
  }

  .list_title {
    font-size: 24px;
    margin-top: -15%;
  }

  .list_num {
    margin-top: 7%;
    font-size: 20px;
  }

  .list_num span {
    font-size: 30px;
  }

  .today_list_font {
    font-size: 14px;
    margin-top: 4%;
  }

  .middle_top_list:nth-of-type(2) {
    border-left: 1px solid #4E72B0;
    border-right: 1px solid #4E72B0;
  }

  .text {
    color: #c3cbde;
  }

  .dv-dec-1 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }

  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;

    .active-ring-name {
      padding-top: 10px;
    }
  }
}
</style>