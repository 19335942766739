<template>
    <div id="lbData">
        <div class="bg-color-black">
            <!-- <div style="padding: 10px 0 10px 20px;">
                
            </div> -->
            <div class="d-flex pt-2 pl-2">
                <span>
                    <icon name="chart-line" class="text-icon"></icon>
                </span>
                <div class="d-flex" style="text-align:center; line-height:40px;" v-for="(item, i) in tabList" :key="i"
                    @click="change(item, i)">
                    <!-- <span class=" text mx-2">网约房-酒店{{ tabValue }}上传排行统计</span> -->
                    <dv-border-box-8 v-if="tabIndex == i">
                        <div :class="['text-cursor', tabIndex == i ? 'active' : '']"
                            style="width: 100px; height: 40px;">{{
                    item.name }}</div>
                    </dv-border-box-8>
                    <dv-border-box-6 v-else :class="[tabIndex == 0 ? 'left' : 'right']">
                        <div :class="['text-cursor', tabIndex == i ? 'active' : '']"
                            style="width: 100px; height: 40px;">
                            {{
                    item.name }}</div>
                    </dv-border-box-6>
                </div>
            </div>
            <div class="d-flex jc-center body-box">
                <dv-scroll-board class="dv-scr-board" :config="config" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['cycle', 'tabValue'],
    data() {
        return {
            config: {
                header: ['酒店名称', '上传数'],
                data: [['正在加载...', '正在加载...']],
                rowNum: 7, //表格行数
                headerHeight: 35,
                headerBGC: '#0f1325', //表头
                oddRowBGC: '#0f1325', //奇数行
                evenRowBGC: '#171c33', //偶数行
                index: true,
                columnWidth: [50, 250],
                align: ['center']
            },
            cdata: [],
            tabList: [
                {
                    name: '上传数',
                    value: '/bigScreen/query/storeUploadRanking'
                }, {
                    name: '未成年数',
                    value: '/bigScreen/query/storeMinorUploadRanking'
                }
            ],
            tabIndex: 0,
            tabName: '上传数',
            url: '/bigScreen/query/storeUploadRanking'
        }
    },
    watch: {
        cdata: {
            handler(val) {
                let arr = val
                let nowData = this.config
                nowData.data = arr
                this.config = { ...nowData }
            },
            deep: true,
        },
        cycle: {
            handler(val) {
                this.getstoreUpload(val)
            }
        },
        tabName: {
            handler(val) {
                let arr = val
                let nowData = this.config
                nowData.header[1] = arr
                this.config = { ...nowData }
            }
        }
    },
    created() {
        this.getstoreUpload()

        setInterval(() => {
            this.getstoreUpload(this.cycle)
        }, 1800000);
    },
    methods: {
        change(obj, index) {
            this.tabIndex = index
            this.tabName = obj.name
            this.url = obj.value
            this.getstoreUpload(this.cycle)
        },
        // 获取左下角排行榜
        getstoreUpload(cycle = 'day') {
            const querystring = require('querystring');
            this.$http.post(this.url, querystring.stringify({
                orgCode: 'A02',
                cycle: cycle
            })).then(res => {
                this.cdata = res.data.result.map(x => Object.assign([], [
                    x.title,
                    x.ucount
                ]))
            })
        }
    }
}
</script>

<style lang="scss" scoped>
$box-height: 460px;
$box-width: 425px;

#lbData {
    padding: 16px;
    padding-top: 20px;
    height: $box-height;
    width: $box-width;
    border-radius: 5px;

    .bg-color-black {
        height: $box-height - 30px;
        border-radius: 10px;

        .text-cursor {
            cursor: pointer;

            &.active {
                color: #4ADEFE;
                font-weight: bolder;
            }
        }

        .left {
            margin-left: 30px;
        }

        .right {
            margin-right: 30px;
        }
    }

    .text {
        color: #c3cbde;
    }

    .body-box {
        border-radius: 10px;
        overflow: hidden;

        .dv-scr-board {
            width: 400px;
            height: 370px;
        }
    }
}
</style>