<template>
  <div>
    <Echart
      id="centreLeft2Chart"
      ref="centreLeft2ChartRef"
      :options="options"
      height="800px"
      width="1000px"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  props: ['cdata'],
  data() {
    return {
      options: {},
    }
  },
  components: {
    Echart,
  },
  watch: {
    cdata: {
      handler(newData) {
        // 设置点的位置(经纬度)
        const geoCoordMap = {
          珠晖区: [112.686324, 26.861063, 20],
          雁峰区: [112.592241, 26.813694, 20],
          石鼓区: [112.607635, 26.933908, 20],
          蒸湘区: [112.540608, 26.89087, 20],
          高新区: [112.530608, 26.83087, 20],
          南岳区: [112.704147, 27.240536, 20],
          衡阳县: [112.359643, 27.092388, 20],
          衡南县: [112.657459, 26.709973, 20],
          衡山县: [112.81971, 27.234808, 20],
          衡东县: [112.990412, 27.083531, 20],
          祁东县: [112.011192, 26.767109, 20],
          耒阳市: [112.917215, 26.374162, 20],
          常宁市: [112.406821, 26.316773, 20],
        }
        let seriesData = [
          {
            name: '珠晖区',
          },
          {
            name: '雁峰区',
          },
          {
            name: '石鼓区',
          },
          {
            name: '蒸湘区',
          },
          {
            name: '高新区',
          },
          {
            name: '南岳区',
          },
          {
            name: '衡阳县',
          },
          {
            name: '衡南县',
          },
          {
            name: '衡山县',
          },
          {
            name: '衡东县',
          },
          {
            name: '祁东县',
          },
          {
            name: '耒阳市',
          },
          {
            name: '常宁市',
          },
        ]
        let convertData = function (data) {
          let scatterData = []
          for (var i = 0; i < data.length; i++) {
            var geoCoord = geoCoordMap[data[i].name]
            if (geoCoord) {
              scatterData.push({
                name: data[i].name,
                value: geoCoord.concat(data[i].value),
              })
            }
          }
          return scatterData
        }
        this.options = {
          showLegendSymbol: true,
          tooltip: {
            trigger: 'item',
            textStyle: {
              fontSize: 14,
              lineHeight: 22,
            },
            position: (point) => {
              // 固定在顶部
              return [point[0] + 50, point[1] - 20]
            },
            // 如果需要自定义 tooltip样式，需要使用formatter
            /*
              formatter: params => {
                return `<div style=""> ... </div>`
              }
            */
          },
          visualMap: {
            min: 0,
            max: 1000,
            left: 240,
            textStyle: {
              color: '#fff',
            },
            pieces: [
              {
                gt: 100,
                label: '> 100',
                color: 'orangered',
              },
              {
                gte: 10,
                lte: 100,
                label: '10 - 100',
                color: 'yellow',
              },
              {
                gte: 0,
                lt: 10,
                label: '0 - 9',
                color: 'lightskyblue',
              },
              // {
              //   value: 0,
              //   label: '无',
              //   color: '#ffd768',
              // },
            ],
          },
          // 底部背景
          geo: {
            show: true,
            aspectScale: 0.85, //长宽比
            zoom: 1.2,
            top: '10%',
            left: '16%',
            map: '衡阳',
            roam: false,
            itemStyle: {
              normal: {
                areaColor: 'rgba(0,0,0,0)',
                shadowColor: 'rgba(7,114,204, .8)',
                shadowOffsetX: 5,
                shadowOffsetY: 5,
              },
              emphasis: {
                areaColor: '#00aeef',
              },
            },
          },
          series: [
            {
              name: '商家总数',
              type: 'map',
              aspectScale: 0.85, //长宽比
              zoom: 1.2,
              mapType: '衡阳', // 自定义扩展图表类型
              // top: '10%',
              // left: '16%',
              // itemStyle: {
              //   normal: {
              //     color: 'red',
              //     areaColor: 'rgba(19,54,162, .5)',
              //     borderColor: 'rgba(0,242,252,.3)',
              //     borderWidth: 1,
              //     shadowBlur: 7,
              //     shadowColor: '#00f2fc',
              //   },
              //   emphasis: {
              //     areaColor: '#cfae08',
              //     borderColor: 'rgba(0,242,252,.6)',
              //     borderWidth: 2,
              //     shadowBlur: 10,
              //     shadowColor: '#00f2fc',
              //   },
              // },
              label: {
                formatter: (params) => `${params.name}`,
                show: true,
                position: 'insideRight',
                textStyle: {
                  fontSize: 14,
                  color: '#333',
                },
                emphasis: {
                  textStyle: {
                    color: '#333',
                  },
                },
              },
              data: newData,
            },
            {
              type: 'effectScatter',
              coordinateSystem: 'geo',
              symbolSize: 7,
              effectType: 'ripple',
              legendHoverLink: false,
              showEffectOn: 'render',
              rippleEffect: {
                period: 4,
                scale: 2.5,
                brushType: 'stroke',
              },
              zlevel: 1,
              itemStyle: {
                normal: {
                  color: '#4adefe',
                  shadowBlur: 5,
                  shadowColor: '#fff',
                },
              },
              data: convertData(seriesData),
            },
          ],
        }
        // 重新选择区域
        this.handleMapRandomSelect()
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    // 开启定时器
    startInterval() {
      const _self = this
      // 应通过接口获取配置时间，暂时写死5s
      const time = 2000
      if (this.intervalId !== null) {
        clearInterval(this.intervalId)
      }
      this.intervalId = setInterval(() => {
        _self.reSelectMapRandomArea()
      }, time)
    },
    // 重新随机选中地图区域
    reSelectMapRandomArea() {
      const length = 13
      this.$nextTick(() => {
        try {
          const map = this.$refs.centreLeft2ChartRef.chart
          let index = Math.floor(Math.random() * length)
          while (index === this.preSelectMapIndex || index >= length) {
            index = Math.floor(Math.random() * length)
          }
          map.dispatchAction({
            type: 'mapUnSelect',
            seriesIndex: 0,
            dataIndex: this.preSelectMapIndex,
          })
          map.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: index,
          })
          map.dispatchAction({
            type: 'mapSelect',
            seriesIndex: 0,
            dataIndex: index,
          })
          this.preSelectMapIndex = index
        } catch (error) {
          console.log(error)
        }
      })
    },
    handleMapRandomSelect() {
      this.$nextTick(() => {
        try {
          const map = this.$refs.centreLeft2ChartRef.chart
          const _self = this
          setTimeout(() => {
            _self.reSelectMapRandomArea()
          }, 0)
          // 移入区域，清除定时器、取消之前选中并选中当前
          map.on('mouseover', function (params) {
            clearInterval(_self.intervalId)
            map.dispatchAction({
              type: 'mapUnSelect',
              seriesIndex: 0,
              dataIndex: _self.preSelectMapIndex,
            })
            map.dispatchAction({
              type: 'mapSelect',
              seriesIndex: 0,
              dataIndex: params.dataIndex,
            })
            _self.preSelectMapIndex = params.dataIndex
          })
          // 移出区域重新随机选中地图区域，并开启定时器
          map.on('globalout', function () {
            _self.reSelectMapRandomArea()
            _self.startInterval()
          })
          this.startInterval()
        } catch (error) {
          console.log(error)
        }
      })
    },
  },
}
</script>
